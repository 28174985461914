import { useEffect, useState } from "react";
import "./App.scss";
import DownloadButton from "./DownloadButton";
import vidaaxtvoe from "./tvoexvidaa.png";
import FooterContent from "./FooterContent";
import Messages from "./Messages";

function App() {
	const [info, setInfo] = useState("collecting info...");
	const [download, setDownload] = useState("Установить приложение");

	useEffect(() => {
		if (typeof window.Hisense_GetCountryCode == "function") {
			if (typeof Hisense_installApp == "function") {
				setInfo("Функция Hisense_installApp доступна. Переходите к установке.");
			} else {
				setInfo("Функция Hisense_installApp не доступна. Проверьте DNS");
			}
		} else {
			setInfo(
				"Функция Hisense_GetCountryCode не доступна. Вы не на Hisense TV."
			);
		}
	}, []);

	const refreshAppsOnHisenseUI = (v_app_info) => {
		var v_refresh_message = {};
		v_refresh_message["type"] = "APPMessage";
		v_refresh_message["MsgType"] = "appControl";
		v_refresh_message["action"] = "updateAppState";
		v_refresh_message["source"] = "browser";
		v_refresh_message["startAppType"] = 0x2;
		v_refresh_message["param"] = {
			event: "AllAppsUpdate",
			SubModuleName: "AllApps",
			startFrom: "",
			appInfo: v_app_info,
		};
		if (!!window["omi_platform"])
			window.omi_platform["sendPlatformMessage"](
				JSON["stringify"](v_refresh_message)
			);
		else
			!!window["opera_omi"] &&
				window.opera_omi["sendPlatformMessage"](
					JSON["stringify"](v_refresh_message)
				);
	};

	const onClick = () => {
		const preview = "https://app.1390760-cu92735.tw1.ru/img/touch-logo.jpg";
		if (window?.Hisense_installApp)
			window?.Hisense_installApp(
				"TVOЁ_debug",
				"TVOЁ",
				preview,
				preview,
				preview,
				"https://app.tvoe.live?device=smart-tv&installed=vidaa",
				"store",
				function (status) {
					if (status) {
						setDownload("Установленно");
					} else {
						setDownload("Ошибка");
						setInfo(
							"Вероятно, приложение уже было установленно. Или ваше устройство не поддерживает данный метод."
						);
					}
					refreshAppsOnHisenseUI("TVOEdebug");
				}
			);
	};

	function getHisenseObject() {
		if (typeof window.HiBrowser != "undefined") {
			console.log("HiBrowser Object found.");

			return window.HiBrowser;
		}

		if (typeof window.Hisense != "undefined") {
			console.log("Hisense Object found.");

			return window.Hisense;
		}
		// platform = 1;
		console.log("No Hisense Object found.");
		return "NOOOOO"; // false or true
	}

	const [hisense, setHisense] = useState("asdasd");

	useEffect(() => {
		setHisense(getHisenseObject());
	}, []);

	return (
		<div className="flex flex-col h-screen">
			{/* <p className="text-white">{hisense}</p> */}

			<Messages />

			{Object.keys(window).map((key, index) =>
				key === "Hisense_installApp" ? (
					<li key={index}>
						<strong>{key}</strong>: {typeof window[key]}
						<pre>Аргументы: {window[key].length}</pre>
						<pre>
							Прототип: {Object.getPrototypeOf(window[key])?.constructor.name}
						</pre>
						<pre>Конструктор: {window[key].constructor.name}</pre>
						<pre>
							Описание свойства:{" "}
							{JSON.stringify(
								Object.getOwnPropertyDescriptor(window, key),
								null,
								2
							)}
						</pre>
						{key === "Hisense_installApp" && (
							<pre>{window[key].toString()}</pre>
						)}
					</li>
				) : (
					""
				)
			)}

			<div>Window location: {window.location.href}</div>
			<div>Domain: {document.domain}</div>
			<div>Cookie: {document.cookie}</div>
			<div>LocalStorage: {JSON.stringify(localStorage)}</div>
			<div>SessionStorage: {JSON.stringify(sessionStorage)}</div>
			{/* <div>Available: {Object.keys(window).join(', ')}</div> */}
			<div>Navigator: {JSON.stringify(window.navigator)}</div>
			<div>Useragent: {navigator.userAgent}</div>
			<div>check if updated</div>

			{/* <header className="flex justify-center items-center my-6 h-20">
				<img src={vidaaxtvoe} alt={"TVOЁ"} />
			</header>

			<div className="flex flex-col justify-center items-center gap-4 h-full">
				<div className="flex justify-center items-center">
					<DownloadButton onClick={onClick} text={download} />
				</div>

				<div className="text-lg text-mainThemeColor opacity-45">{info}</div>
			</div>

			<footer>
				<FooterContent />
			</footer> */}
		</div>
	);
}

export default App;

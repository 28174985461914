import React, { useEffect, useState } from "react";

const Messages = () => {
	const [messages, setMessages] = useState([]);

	useEffect(() => {
		const originalConsoleLog = console.log;

		console.log = function (...args) {
			setMessages((prevMessages) => [...prevMessages, ...args]);
			originalConsoleLog(...args);
		};

		const originalConsoleError = console.error;
		console.error = function (...args) {
			setMessages((prevMessages) => [...prevMessages, ...args]);
			originalConsoleError(...args);
		};

		const originalConsoleWarn = console.warn;
		console.warn = function (...args) {
			setMessages((prevMessages) => [...prevMessages, ...args]);
			originalConsoleWarn(...args);
		};

		const originalConsoleInfo = console.info;
		console.info = function (...args) {
			setMessages((prevMessages) => [...prevMessages, ...args]);
			originalConsoleInfo(...args);
		};

		return () => {
			console.log = originalConsoleLog;
			console.error = originalConsoleError;
			console.warn = originalConsoleWarn;
			console.info = originalConsoleInfo;
		};
	}, [setMessages]);

	return (
		<div>
			<h2>Сообщения:</h2>
			<ul>
				{messages.map((message, index) => (
					<li key={index}>{message}</li>
				))}
			</ul>
		</div>
	);
};

export default Messages;

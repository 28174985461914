const DownloadButton = ({ text, onClick }) => {
	return (
		<button
			onClick={() => onClick()}
			className="text-xl font-medium px-4 py-3 rounded-2xl p-2 hover:opacity-80 active:opacity-60 transition-opacity bg-gradient-to-r from-gradient1 to-gradient2 text-black"
		>
			{text}
		</button>
	);
};

export default DownloadButton;
